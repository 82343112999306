/**
 * @fileoverview A thin wrapper for the Spark Carousel, customized for Odopod.
 */

import $ from 'jquery';
import SparkCarousel from 'spark-carousel';
import SparkResponsiveImages from 'spark-responsive-images';
import SparkWindowEvents from 'spark-window-events';
import Device from 'spark-device-enum';
import { animation } from 'spark-helpers';
import Track from 'js/track';

const Stepper = animation.Stepper;

function preventDefaultAction(evt) {
  evt.preventDefault();
}

class OdoCarousel extends SparkCarousel {
  constructor(element) {
    super(element, {
      pagination: true,
      template: {
        paddleNextInner: '<svg viewBox="0 0 17.021 29.798" enable-background="new 0 0 17.021 29.798"><path d="M2.121 0l-2.121 2.121 12.778 12.778-12.778 12.778 2.121 2.121 14.9-14.899z"/></svg>',
        paddlePrevInner: '<svg viewBox="0 0 17.021 29.798" enable-background="new 0 0 17.021 29.798"><path d="M14.899 29.798l2.122-2.121-12.778-12.778 12.778-12.778-2.122-2.121-14.899 14.899z"/></svg>',
      },
      getPaginationHtml(instance) {
        const totalSlides = instance.getSlides().length;

        let dotsHtml = '';
        for (let i = 0; i < totalSlides; i++) {
          dotsHtml += SparkCarousel.template(instance.options.template.paginationDot, {
            index: i,
            index1: i + 1,
            slideId: instance.getSlide(i).id,
          });
        }

        const wrapper = `<div class="container ${SparkCarousel.settings.Classes.PAGINATION}"><div class="row"><div class="col-12@sm"><div class="odo-carousel-pagination"><span class="odo-carousel-pagination-text type-body-2 marginless"></span>`;
        const dots = '<nav role="tablist">';
        const dotsEnd = '</nav>';
        const wrapperEnd = '</div></div></div></div>';

        return wrapper + dots + dotsHtml + dotsEnd + wrapperEnd;
      },
    });

    this.$el = $(this.element);

    // For almost-full-bleed carousels, the first two responsive images will load.
    this.images = new Array(this.getSlides().length);
    this.images[0] = true;
    this.images[1] = true;

    this._descriptions = this.element.querySelectorAll('.js-pagination-description');
    this._paginationTextEl = this.element.querySelector('.odo-carousel-pagination-text');

    this._setPaddleHeight();
    this._setPaginationText(this.options.startIndex);
    this.isPeeked = false;
    this.stepper = null;

    this._bindEvents();

    if (!Device.HAS_TOUCH_EVENTS) {
      this._setupPeek();
    }
  }

  _bindEvents() {
    this._onSlideStart = this._handleSlideStart.bind(this);
    this._onSlideEnd = this._handleSlideEnd.bind(this);
    this._onSetPaddleHeight = this._setPaddleHeight.bind(this);

    this._resizeId = SparkWindowEvents.onResize(this._onSetPaddleHeight);
    this.$el.find('img').on('load', this._onSetPaddleHeight);
    this.on(SparkCarousel.settings.EventType.SLIDE_START, this._onSlideStart);
    this.on(SparkCarousel.settings.EventType.SLIDE_END, this._onSlideEnd);
  }

  /**
   * Add event listeners needed for peek functionality.
   */
  _setupPeek() {
    this._onPeekNext = this._peekNext.bind(this);
    this._onPeekPrevious = this._peekPrevious.bind(this);
    this._onPeekReset = this._resetPeek.bind(this);
    this.getNextPaddle().addEventListener('mouseenter', this._onPeekNext, false);
    this.getNextPaddle().addEventListener('mouseleave', this._onPeekReset, false);
    this.getPreviousPaddle().addEventListener('mouseenter', this._onPeekPrevious, false);
    this.getPreviousPaddle().addEventListener('mouseleave', this._onPeekReset, false);
  }

  _setPaginationText(i) {
    this._paginationTextEl.innerHTML = this._descriptions[i].textContent;
  }

  /**
   * Retrieve the stored paddle element
   * @return {Element}
   */
  getPreviousPaddle() {
    return this._paddlePrevious;
  }

  /**
   * Retrieve the stored paddle element
   * @return {Element}
   */
  getNextPaddle() {
    return this._paddleNext;
  }

  /**
   * When the slide changes, update the active slide copy.
   * @param {CarouselEvent} evt Event object.
   */
  _handleSlideStart({ hasSlideChanged, to }) {
    if (hasSlideChanged) {
      this._setPaginationText(to);
    }
  }

  /**
   * Load neighboring images when slides finish.
   * @param {CarouselEvent} evt Event object.
   */
  _handleSlideEnd({ hasSlideChanged, to, from }) {
    if (hasSlideChanged) {
      Track.event('carousel', 'navigate', 'slide changed');
    }

    const next = to > from ? to + 1 : to - 1;
    if (!hasSlideChanged || this.images[next] || this.isIndexOutOfRange(next)) {
      return;
    }

    // Load the neighboring responsive image.
    const image = this.getSlide(next).querySelector('.spark-responsive-img');

    SparkResponsiveImages.load(image);

    this.images[next] = true;
  }

  /** @override */
  _handleDragEnd(...args) {
    if (this.hasDragged) {
      this._preventNextClick();
    }

    SparkCarousel.prototype._handleDragEnd.apply(this, args);
  }

  /**
   * Prevent clicks from navigating the user inside a carousel after it has been
   * dragged. jQuery's `one` is not used here because the click event does not
   * always fire, especially on mobile.
   */
  _preventNextClick() {
    this.$el.on('click', preventDefaultAction);
    setTimeout(() => {
      this.$el.off('click', preventDefaultAction);
    }, 300);
  }

  /** @override */
  _canNavigate(...args) {
    return this.isPeeked || SparkCarousel.prototype._canNavigate.apply(this, args);
  }

  /**
   * Set the height of the paddle elements in pixels so that percentage values
   * still work.
   */
  _setPaddleHeight() {
    const carouselHeight = this.element.offsetHeight;
    const paddleHeight = Math.round(carouselHeight * 0.4);
    this.getNextPaddle().style.height = `${paddleHeight}px`;
    this.getNextPaddle().style.top = `${paddleHeight / -2}px`;
    this.getPreviousPaddle().style.height = `${paddleHeight}px`;
    this.getPreviousPaddle().style.top = `${paddleHeight / -2}px`;
  }

  /**
   * Calculate the peeking offset of the carousel element based on the current
   * slide index.
   * @param {boolean} isNext Whether to peek next or previous.
   * @return {number}
   */
  _getPeekOffset(isNext) {
    const x = this.getSlide(this.getSelectedIndex()).offsetLeft * -1;
    const width = this.getWrapper().offsetWidth;
    const peekPixels = width * OdoCarousel.PEEK_AMOUNT;
    const offset = isNext ?
      x - peekPixels :
      x + peekPixels;
    return Math.round(offset);
  }

  /**
   * Retrieve the current offset of the carousel element.
   * @return {number}
   */
  _getCurrentOffset() {
    const matrix = getComputedStyle(this.getCarouselElement())[Device.Dom.TRANSFORM];
    return parseFloat(matrix.split(',')[4]);
  }

  /**
   * Stop the peeking animation.
   */
  _cancelPeeking() {
    if (this.stepper) {
      this.stepper.cancel();
      this.stepper = null;
    }
  }

  /**
   * Peek next or previous.
   * @param {boolean} isNext Which direction to peek.
   */
  _peek(isNext) {
    // The carousel is not yet able to cancel transitioning to a slide without
    // side effects. The user will have to mouse out and back in on the paddle
    // to trigger the peek.
    // Also abort if the user has dragged the carousel and they are now on top
    // of one of the nav paddles.
    if (this.isTransitioning || this.hasDragged) {
      return;
    }

    this._cancelPeeking();

    const start = this._getCurrentOffset();
    const end = this._getPeekOffset(isNext);

    this.isPeeked = true;

    this.getCarouselElement().style[Device.Dom.TRANSITION_DURATION] = '0ms';

    this.stepper = new Stepper({
      start,
      end,
      step: this._stepPeek,
      context: this,
      duration: OdoCarousel.PEEK_DURATION,
    });

    this.stepper.onfinish = () => {
      this.stepper = null;
    };
  }

  /**
   * Peek the carousel to the next slide.
   */
  _peekNext() {
    if (!this.isLastSlide()) {
      this._peek(true);
    }
  }

  /**
   * Peek the carousel to the previous slide.
   */
  _peekPrevious() {
    if (!this.isFirstSlide()) {
      this._peek(false);
    }
  }

  /**
   * Go back to the resting state for the carousel.
   */
  _resetPeek() {
    if (this.hasDragged || !this.isPeeked) {
      return;
    }

    this._cancelPeeking();
    this.setSelectedIndex(this.getSelectedIndex());
    this.isPeeked = false;
  }

  /**
   * Method called on every frame of the peek animation.
   * @param {number} pixel The offset.
   */
  _stepPeek(pixel) {
    this.getCarouselElement().style[Device.Dom.TRANSFORM] = this._getCssPosition(`${pixel}px`);
  }

  /**
   * Remove listeners and references.
   */
  dispose() {
    this.$el.find('img').off('load', this._onSetPaddleHeight);
    this.off(SparkCarousel.settings.EventType.SLIDE_START, this._onSlideStart);
    this.off(SparkCarousel.settings.EventType.SLIDE_END, this._onSlideEnd);

    if (!Device.HAS_TOUCH_EVENTS) {
      this.getNextPaddle().removeEventListener('mouseenter', this._onPeekNext, false);
      this.getNextPaddle().removeEventListener('mouseleave', this._onPeekReset, false);
      this.getPreviousPaddle().removeEventListener('mouseenter', this._onPeekPrevious, false);
      this.getPreviousPaddle().removeEventListener('mouseleave', this._onPeekReset, false);
    }

    this.$el = null;

    SparkWindowEvents.remove(this._resizeId);
    super.dispose();
  }

  /**
   * Overrides the original functionality so that if we're on the last slide, we
   * instead rewind to the first slide.
   *
   * @see spark-carousel:goToNextSlide
   * @override
   */
  goToNextSlide() {
    if (this.isLastSlide()) {
      return this.setSelectedIndex(0);
    }

    return super.goToNextSlide();
  }
}

OdoCarousel.settings = SparkCarousel.settings;

OdoCarousel.PEEK_AMOUNT = 0.09;
OdoCarousel.PEEK_DURATION = 200;

/**
 * Overrides the original functionality so that we don't check if the domIndex
 * is "out of range", as this will break rewind/fast forwarding.
 *
 * TODO why isn't this overridden above?
 *
 * @see spark-carousel:_canNavigate
 * @override
 */
SparkCarousel.prototype._canNavigate = function _canNavigate(domIndex, noAnimation) {
  const isSameSlideWithAnimation = domIndex === this.domIndex && !noAnimation;

  // 1) The index is out of range and the carousel isn't set to loop. Silently
  // exit here instead of throwing errors everywhere.
  // 2) If here is a transition end waiting, the transition end must be
  // executed for the carousel to perform properly. so exit here too.
  // 3) Trying to go to the slide it's already on and no dragging has occured
  // and there is animation.
  return !(
    (!this.isLastSlide() && !this.options.isLooped && this.isIndexOutOfRange(domIndex)) ||
    (this.isTransitioning) ||
    (isSameSlideWithAnimation && !this.hasDragged));
};

export default OdoCarousel;
