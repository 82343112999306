'use strict';

/**
 * Returns the object type as a string.
 *
 * @param {object}
 * @return {string}
 *
 * @example [] => "[object Array]"
 * @example {} => "[object Object]"
 */
export function getObjectType(object) {
  return Object.prototype.toString.call(object);
}

/**
 * Implodes an array into a comma-separated list, or returns the input
 * if the input is not an array.
 *
 * @param {string|array} input - The string or array to implode.
 * @param {boolean} [doEncode=false] - Encode the input as well.
 * @return {string}
 */
export function implode(input, doEncode = false) {

  if (typeof input !== 'string') {
    input = input.join(',');
  }

  return doEncode ? encodeURIComponent(input) : input;
}

/**
 * Implodes an array or string as well as strips a token from
 * the string or each item in the array. This is uesful for removing hastags or
 * @'s.
 *
 * @param {string|array} input - The string or array to implode.
 * @param {string} stripToken - The token to strip.
 * @param {boolean} [doEncode=false] - Encode the input as well.
 * @return {string}
 */
export function implodeAndStrip(input, stripToken, doEncode = false) {

  if (typeof input === 'string') {
    input = input.replace(', ', ',');
    input = input.split(',');
  }

  input = input.map(item => item.replace(stripToken, '')).join(',');

  return doEncode ? encodeURIComponent(input) : input;
}

/**
 * Transforms an array-like nodeList into an array.
 *
 * @param {object} arrayLike
 * @return {object}
 */
export function toArray(arrayLike) {
  return [].slice.call(arrayLike);
}
