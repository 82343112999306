import $ from 'jquery';

/**
 * Overlay grid constructor.
 * @constructor
 */
class GridOverlay {
  constructor() {
    // Ensure the browser supports media queries with matchMedia.
    if (matchMedia && matchMedia('only all') && matchMedia('only all').matches) {
      $(document).on('keypress.gridoverlay', $.proxy(this.handleKeyPress_, this));

      /**
       * @type {MediaQueryList}
       */
      this.mediaQueryList = matchMedia(`(min-width:${GridOverlay.MOBILE_BREAKPOINT})`);

      this.gridListener_ = () => {
        this._destroyGridOverlay();
        this._makeGridOverlay();
      };
    }
  }

  /**
   * @param {jQuery.Event} e Event object.
   * @private
   */
  handleKeyPress_({ which }) {
    if (which !== GridOverlay.TRIGGER_KEY_CODE) {
      return;
    }

    if (GridOverlay.IS_DEBUG_TOGGLED) {
      this._destroyGridOverlay();
      this.mediaQueryList.removeListener(this.gridListener_);
    } else {
      this._makeGridOverlay();
      this.mediaQueryList.addListener(this.gridListener_);
    }

    GridOverlay.IS_DEBUG_TOGGLED = !GridOverlay.IS_DEBUG_TOGGLED;
  }

  /**
   * Makes a grid overlay on the page.
   * @private
   */
  _makeGridOverlay() {
    let container;

    if (this.mediaQueryList.matches) {
      container = this._getFullGridOverlay(GridOverlay.DESKTOP_COLUMNS, GridOverlay.COLUMN_CLASS);
    } else {
      container = this._getFullGridOverlay(
        GridOverlay.MOBILE_COLUMNS, GridOverlay.MOBILE_COLUMN_CLASS);
    }

    document.body.appendChild(container);
  }

  /**
   * Creates the necessary elements.
   * @param {number} columns Number of columns.
   * @param {string} gridClass Css grid class.
   * @return {Element}
   * @private
   */
  _getFullGridOverlay(columns, gridClass) {
    const container = document.createElement('div');
    container.className = GridOverlay.CONTAINER_CLASS;
    container.id = 'grid';
    container.style.cssText = `height:${this.getGridHeight()}px;position:absolute;top:0;left:0;right:0;background-color:transparent;pointer-events:none;z-index:999;`;

    const row = document.createElement('div');
    row.className = GridOverlay.ROW_CLASS;
    row.style.height = 'inherit';

    for (let i = 1; i <= columns; i++) {
      const col = document.createElement('div');
      col.className = gridClass;
      col.style.height = 'inherit';

      const inner = document.createElement('div');
      inner.style.backgroundColor = GridOverlay.OVERLAY_COLOR;
      inner.style.opacity = 0.4;
      inner.style.height = 'inherit';
      col.appendChild(inner);
      row.appendChild(col);
    }

    container.appendChild(row);

    return container;
  }

  /**
   * Returns the higher of clientHeight or scrollHeight.
   * @return {number} Height the grid will be.
   */
  getGridHeight() {
    return Math.max(document.documentElement.scrollHeight, document.documentElement.clientHeight);
  }

  /**
   * Removes #grid.
   * @private
   */
  _destroyGridOverlay() {
    document.body.removeChild(document.getElementById('grid'));
  }

  /**
   * Dispose.
   */
  dispose() {
    $(document).off('keypress.gridoverlay');
  }
}

/**
 * Whether the grid is visible.
 * @type {boolean}
 */
GridOverlay.IS_DEBUG_TOGGLED = false;

// question mark.
GridOverlay.TRIGGER_KEY_CODE = 63;

GridOverlay.CONTAINER_CLASS = 'container';
GridOverlay.ROW_CLASS = 'row';
GridOverlay.COLUMN_CLASS = 'col-1@sm';
GridOverlay.MOBILE_COLUMN_CLASS = 'col-1@sm';

GridOverlay.MOBILE_COLUMNS = 6;
GridOverlay.DESKTOP_COLUMNS = 12;

GridOverlay.MOBILE_BREAKPOINT = '768px';

GridOverlay.OVERLAY_COLOR = '#51ECB8';

export default new GridOverlay();
