/**
 * SparkShare component.
 *
 * @author Nathan Buchar <nathan.buchar@nurun.com>
 */

'use strict';

import $ from 'jquery';

import settings from './settings';
import * as services from './services';
import { getObjectType, implode, toArray } from './utils';

class SparkShare {

  /**
   * SparkShare - Spark component constructor.
   *
   * This calls the private _init method to build prepare share components within
   * the DOM.
   *
   * @constructor
   */
  constructor() {
    this.settings = settings;
    this.services = services;

    // An array of share data objects. These objects may contain information
    // regarding the root element, and beforeSend and afterSend functionality.
    this.shares = [];

    // Phantom JS seems to dislike bind.
    // this._shareClickHandler = this._handleShareClick.bind(this);
    // => TypeError: 'undefined' is not a function (evaluating 'this._handleShareClick.bind(this)')

    this._init();
  }

  /**
   * Initialize the Spark component. Register all share buttons that exist
   * in the DOM when the page is ready.
   *
   * @see _registerShareButtons
   */
  _init() {
    this._registerShareButtons();
  }

  /**
   * This registers all SparkShare components that are
   * rendered to the DOM on page load. This will not prepare SparkShare components
   * that are added to the DOM dynamically. These must be registered using the
   * public `add` method (@see add).
   *
   * @see _.add
   */
  _registerShareButtons() {
    var selector = '.' + this.settings.ClassName.BASE;
    var shareElements = toArray(document.querySelectorAll(selector));

    // Pass an array of objects to the `_add` method. These objects simply contain
    // an `element` key that references the DOM element.
    this._add(shareElements.map(element => ({ element })));
  }

  /**
   * Iterates through each element passed into the method, prepares it and
   * pushes the completed data object to the `shares` array.
   *
   * @param {object Array} newShareData - An array of new share data.
   * @see _getDefaultOptions
   */
  _add(newShareData) {
    var newShares = [];

    newShareData.forEach(function (item) {
      var type = getObjectType(item);
      var data = null;

      if (item.element) {
        data = item;
      } else if (/^\[object HTML/.test(type)) {
        data = {
          element: item,
        };
      }

      if (data) {
        this._listenForShareClick(data);
        this.shares.push(data);
        newShares.push(data);
      }
    }, this);

    return newShares;
  }

  /**
   * Disposes of all references and event listeners to the share
   * button. To reverse this, you must call `.add` and pass in the element and
   * options again.
   *
   * @param {Element} element - A reference to the share button's element.
   * @return {number|void} - If a share button was disposed, this will return `1`,
   *   (the length of the number of items disposed of), otherwise this will not
   *   return.
   */
  _dispose(element) {
    for (var i = 0; i < this.shares.length; i++) {
      if (this.shares[i].element === element) {
        element.removeEventListener('click');

        return this.shares.splice(i, 1).length;
      }
    }
  }

  /**
   * Initiate sharing. Extend the default options, fetch the service,
   * parse the input, then perform the share.
   *
   * @param {object} input - The share data to use. This may include the service,
   *   url, text, hashtags, beforeSend, etc.
   * @see _getService
   * @see _parseInput
   * @see _performShare
   */
  _share(input) {
    input = $.extend(this._getDefaultOptions(), input);

    // Fetch the service config from the string provided.
    var service = this._getService(input);

    // The service could not be determined or was not defined; Exit early.
    if (!service) {
      return false;
    }

    // Parse the output (params, query string, etc) from the data provided.
    var output = this._parseInput(input, service);

    // Perform the share operation.
    this._performShare(input, output, service);

    return true;
  }

  /**
   * Performs the share operation. This will call the `before`
   * method, reapply any modified input, open the window, then perform the
   * `after` functionality.
   *
   * @param {object} input - The input data for the share as defined by the user.
   * @param {object} outout - The output data for the share as generated by the
   *   SparkShare internals, such as the query  string, window properties, and
   *   parsed paramaters.
   * @param {object} service - The service configuration. @see ./services
   */
  _performShare(input, output, service) {
    input.before.call(this, output, function () {
      var windowObjectReference;

      // Reapply new data if applicable.
      if (arguments.length) {
        if (arguments[0] === false) {
          return;
        } else if ('data' in arguments[0]) {
          input = $.extend(input, arguments[0]);
        } else {
          input = $.extend(input, {
            data: arguments[0],
          });
        }

        output = this._parseInput(input, service);
      }

      // Open the share window.
      if (service !== this.services.email) {
        var features = service.Features;

        windowObjectReference = this._open(output.url, output.name, features);
      } else {
        // Prevent opening empty window if sharing via email.
        window.location.href = output.url;
      }

      // Call the `after` functionality.
      input.after.call(this, output, windowObjectReference);
    }.bind(this));
  }

  /**
   * Gets the service configuration from the given string.
   *
   * @param {object} data - Share input data.
   * @return {object|void} - The service configuration, or void if no service was
   *   defined.
   */
  _getService(data) {
    var service = data.service || $(data.element).data('service');

    // Service undefined; Exit early.
    if (!service) {
      return;
    }

    // Iterate over each service and compare the given service to the object key.
    for (var key in this.services) {
      if (key === service) {
        return this.services[key];
      }
    }
  }

  /**
   * Parses the given input and exports the technical output based
   * on the service schema. This includes items such as the query string, window
   * data, and params.
   *
   * @param {object} input - Share input data.
   * @param {object} service - The service configuration.
   * @return {object} output - The parsed output.
   * @see _parseparamsFromInput
   * @see _assembleQueryStringFromParams
   * @see _constructWindowData
   */
  _parseInput(input, service) {
    var output = {};

    var params = this._parseParamsFromInput(input, service);
    var queryString = this._assembleQueryStringFromParams(params);
    var windowData = this._constructWindowData(service, queryString);

    // Set output values.
    output.params = params;
    output.queryString = queryString;
    output.url = windowData.url;
    output.name = windowData.name;

    return output;
  }

  /**
   * Parse the parameters from the given input and service schema. Defining a
   * service schema allows us to always use "url" for all our share buttons,
   * regardless of their service, as long as the proper key is defined in its
   * respective parameter schema.
   *
   * If a parameter has a `parse` method within the object, this method will be
   * called and will pass in the input value for that particular parameter. This
   * allows us to customize how a parameter is parsed on a per-parameter and per-
   * service basis without adding needless code within conditional blocks below.
   *
   * Order of priorities:
   *   input value > `data` attributes (if applicable) > default > `void`
   *
   * @param {object} input - Share input data.
   * @param {object} service - The service configuration.
   * @return {object} obj - The parsed parameters.
   */
  _parseParamsFromInput(input, service) {
    var elementData = input.element ? $(input.element).data() : null;
    var data = input.data;
    var obj = {};

    // Iterate through each valid paramater for the given service.
    for (var param in service.params) {
      var config = service.params[param];
      var friendly = config.friendly;
      var value = null;

      // Check if the parameter was defined when `share` was called.
      if (friendly in data) {
        value = data[friendly];
      }

      // Check if the parameter is defined as a data attribute on the element,
      // assuming an element is defined.
      else if (elementData && friendly in elementData) {
        value = elementData[friendly];
      }

      // The param was not defined; Check for default values.
      else if (typeof config.default !== 'undefined') {
        value = config.default;
      }

      // No value was determined and will therefore be skipped.
      if (!value) {
        continue;
      }

      // Parse the parameter and set its value.
      obj[param] = config.parse ? config.parse(value).toString() : value.toString();
    }

    return obj;
  }

  /**
   * Assembles the query string from an enumerated param object.
   *
   * For example { foo: 'bar', baz: 'qux' } => "?foo=bar&baz=qux"
   *
   * @param {object} data - Parameter data.
   * @return {string} query - The finalized query string.
   */
  _assembleQueryStringFromParams(data) {
    var params = [];
    var query = '';

    if ('to' in data) {
      query = implode(data.to);
    }

    for (var param in data) {
      params.push(param + '=' + data[param]);
    }

    query += '?' + params.join('&');

    return query;
  }

  /**
   * Constructs the window data for the particular service.
   *
   * @param {object} service - The service configuration.
   * @param {string} [queryString=''] - The query string to append to the
   *   service's base url.
   * @return {object}
   */
  _constructWindowData(service, queryString) {
    return {
      url: service.BASE + (queryString || ''),
      name: this.settings.WINDOW_NAME,
    };
  }

  /**
   * Opens a new browser window.
   *
   * @param {string} url - The URL to be loaded in the newly opened window.
   * @param {string} windowName - A string name for the new window. The name
   *   should not contain any whitespace characters. NOTE that windowName does not
   *   specify the title of the new window. If a window with the same name already
   *   exists, then the URL is loaded into the existing window.
   * @return {object|null} - A reference to the newly created window. If the call
   *   failed, it will be null. The reference can be used to access properties and
   *   methods of the new window provided it complies with Same origin policy
   *   security requirements.
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Window/open}
   */
  _open(url, windowName, windowFeatures = '') {
    return window.open(url, windowName, windowFeatures);
  }

  /**
   * Listen for click events on the share button.
   *
   * @param {object} data - The share input data.
   * @see _handleShareClick
   */
  _listenForShareClick(data) {
    data.element.addEventListener('click', (function (_this) {
      return function (evt) {
        evt.preventDefault();

        // Handle the click.
        return _this._handleShareClick(data);
      };
    })(this));
  }

  /**
   * Handles when a share button is clicked--Initiates the
   * share functionality.
   *
   * @param {object} data - The share input data.
   */
  _handleShareClick(data) {
    return this._share(data);
  }

  /**
   * Returns a clone of the default options object.
   *
   * @return {object}
   */
  _getDefaultOptions() {
    return $.extend({}, SparkShare.Options);
  }

  /**
   * Public-facing `add` method. Use this to register your share button with
   * SparkShare if the DOM element was added to the DOM dynamically.
   *
   * @example
   *   SparkShare.add(myDOMElement);
   *
   *    or
   *
   *   SparkShare.add({
   *     element: myDOMElement
   *   });
   *
   * @return {number} - The number of share button added to the internal cache.
   * @public
   * @see _add
   */
  add(...args) {
    if (!args.length) {
      return [];
    }

    // Determine the argument type.
    var type = getObjectType(args[0]);

    if (/^\[object HTML/.test(type)) {
      // A DOM element was passed in.
      return this._add([{ element: args[0] }]);
    } else if (type === '[object Object]' && !args[0].jquery) {
      // A data object was passed in.
      return this._add([args[0]]);
    } else if (type === '[object Array]') {
      // An array was passed in.
      return this._add(args[0]);
    }
  }

  /**
   * Public-facing `share` method. Use this to immediately invoke the share
   * functionality--even without an element.
   *
   * @example
   *   SparkShare.share({
   *     service: 'twitter',
   *     data: {
   *       url: 'http://odopod.com/'
   *       text: 'Check this out!',
   *       via: 'Nurun',
   *       hashtags: ['rad', 'odoshare']
   *     }
   *   });
   *
   * @param {object} options - Share options.
   * @return {bool|void} - Will return true if the share was successfull, will
   *   return void if there was a problem sharing.
   * @see _share
   */
  share(options) {
    return this._share(options);
  }

  /**
   * Public-facing `dispose` method. This will remove the share button from the
   * internal cache if it exists.
   *
   * @param {Element} element - A reference to the share button's element.
   * @see _dispose
   */
  dispose(element) {
    return this._dispose(element);
  }

}

/**
 * Default share input options. These may be overwritten by the user.
 */
SparkShare.Options = {
  data: {},
  before(output, next) {
    next();
  },

  after() {
    // No operation.
  },
};

export default new SparkShare();
