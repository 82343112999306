import 'spark-responsive-images';
import 'spark-helpers';
import 'spark-share';

import 'css/styles.scss';

import 'js/odo-carousel';
import 'js/odo-carousel-fade';
import 'js/track';
import Modal from 'modules/modal/modal';

import MainNav from 'modules/main-nav/main-nav';
import GlobalFooter from 'modules/global-footer/global-footer';

// `import` doesn't allow for conditional imports. These should only be included
// in the development build.
// eslint-disable-next-line no-undef
if (DEBUG) {
  require('js/grid-overlay'); // eslint-disable-line global-require
  require('js/design-mode-toggle'); // eslint-disable-line global-require
}

MainNav.initializeAll();
Modal.initializeAll();
GlobalFooter.initializeAll();
