/**
 * @fileOverview Use google analytics to track events.
 *
 * Usage:
 *
 * import Track from 'js/track';
 *
 * Track.event('expand', 'click', 'button', 1)
 * Track.event('carousel', 'navigate', 'nav buttons', 1)
 */

export default {
  event(category, action, label, value) {
    window.ga('send', 'event', category, action, label, value);
  },
};
