import $ from 'jquery';
import Modal from 'js/modal';
class ModalService{
  constructor(element) {
    this.element = element;
    this.modal = new Modal($('#' + this.element.getAttribute('data-modal-link')), {
      center: false,
      centerOnMobile: false,
      animationType: 'fade',
    });
  }

  dispose() {
    this.carousel.dispose();
    this.element = null;
  }

  static initializeAll() {
    return $('[data-modal-link]').map((i, el) => new ModalService(el)).get();
  }
}

export default ModalService;
