/**
 * @fileoverview A global modal component.
 *
 * @author Evan Vaughn
 */

import $ from 'jquery';
import { animation } from 'spark-helpers';
import SparkWindowEvents from 'spark-window-events';

class Modal {
    constructor(element, opts) {
        this.options = $.extend(true, {}, Modal.Defaults, opts);

        /**
         * Base Element
         * @type {Element}
         * @private
         */
        this.element = element;

        /**
         * Base Element
         * @type {jQuery}
         * @private
         */
        this.$element = $(element);

        /**
         * Body Element
         * @type {jQuery|Element}
         * @private
         */
        this.$body = $(document.body);

        /**
         * DxoModal Content - jQuery
         * @type {jQuery|Element}
         * @private
         */
        this.$content = null;

        /**
         * DxoModal Content - DOM Element
         * @type {Element}
         * @private
         */
        this._content = null;

        /**
         * DxoModal Id
         * @type {String}
         * @private
         */
        this._id = null;

        /**
         * DxoModal backdrop
         * @type {jQuery|Element}
         * @private
         */
        this.$backdrop = null;

        /**
         * Will the modal animate in?
         * @type {Boolean}
         * @private
         */
        this._isAnimated = false;

        this._closeButton = $(document.querySelector('#main-nav__contact'));

        this._init();
    }

    /**
     * Saves DOM references and attaches event listeners
     * @private
     */
    _init() {
        this.$content = this.$element.find(`.${Modal.Selectors.CONTENT}`);
        this._content = this.$content[0];
        this._id = this.$element.attr('id');
        this.triggers = this.$body.find(`*[data-target="${this._id}"]`);
        this._video = this.$content.find('.video');

        if (this.options.animationType) {
            this._isAnimated = true;
            this._animationType = this.options.animationType;
        }

        this._initListeners();
    }

    /**
     * Creates actual modal elements
     * @private
     */
    _create() {
            const modal = document.createElement('div');
            modal.className = 'modal on-screen';

            if (this._isAnimated) {
                modal.className += ` ${this._animationType}`;
            }

            const backdrop = document.createElement('div');
            backdrop.className = 'modal-backdrop';

            const container = document.createElement('div');
            container.className = 'modal-container';

            const innerContainer = document.createElement('div');
            innerContainer.className = 'modal-inner';

            if (this.options.center) {
                innerContainer.className += ' vertical-center';
            }

            const gridContainer = document.createElement('div');
            gridContainer.className = 'inner-container container container-no-ratio-max-width';

            if (this.options.isFullScreen) {
                modal.className += ' full-screen';
            }

            const closeButton = $('<div class="close"><svg class="svg-icon md icon-close"><use xlink:href="#close"></use></svg></div>')[0];

            gridContainer.appendChild(this._content);
            innerContainer.appendChild(gridContainer);
            container.appendChild(innerContainer);

            if (this.options.isFullScreen || this._video.length > 0) {
                closeButton.className += ' close-full';
                container.appendChild(closeButton);
            } else {
                this._content.appendChild(closeButton);
            }

            modal.appendChild(container);
            modal.appendChild(backdrop);

            if (this._video.length > 0) {
                this._createVideoContainer();
            }

            this.$modal = $(modal);
            this.$backdrop = $(backdrop);
            this.$container = $(container);
            this.$gridContainer = $(gridContainer);
            this.$closeButton = $(closeButton);

            this.contentAspect = this._content.clientHeight / this._content.clientWidth;

            if (this.options.isFullScreen) {
                this._determineOffset();
                this.resizeId = SparkWindowEvents.onResize(this._determineOffset.bind(this));
            }

            if (!this.options.centerOnMobile) {
                this.$modal.addClass('full-mobile-height');
            }
        this._show();
    }

    /**
     * Click event listeners for modal triggers
     * @private
     */
    _initListeners() {
        this.triggers.on('click', () => {
            if (this.triggers[0].classList.contains('openned')) {
                document.querySelector('html').style.overflow = "auto";
                this._hide();
                this.triggers[0].classList.remove('openned');
            } else{
                document.querySelector('html').style.overflow = "hidden";
                this._create();
                this._showAnimatedContent();
                this.triggers[0].classList.add('openned');
            }
        });
    }

    /**
     * Calculates whether or not to rescale modal element
     * @private
     */
    _determineOffset() {
        const windowHeight = document.documentElement.clientHeight;
        const windowWidth = document.documentElement.clientWidth;
        const windowAspect = windowHeight / windowWidth;

        this._content.style.maxWidth = 'none';
        this._content.style.margin = '0 auto';

        if (this.contentAspect > windowAspect) {
            this._recalculateAspect();
        }
    }

    /**
     * Rescales modal element to fit within landscape view
     * @private
     */
    _recalculateAspect() {
        const windowHeight = document.documentElement.clientHeight;

        // var windowWidth = document.documentElement.clientWidth;

        this._content.style.maxWidth = `${windowHeight / this.contentAspect}px`;
        this._content.style.margin = '0 auto';
    }

    /**
     * Display modal element
     * @private
     */
    _show() {
        this.$body.addClass(Modal.Selectors.OPEN);
        this.$body.append(this.$modal);
        this._showContent();
    }

    /**
     * Display modal content
     * @private
     */
    _showContent() {
        const _this = this;

        // if there is an animation, will add the 'in' class and wait until
        // the backdrop animation is finished before displaying content
        if (this._isAnimated) {
            setTimeout(() => {
                _this.$modal.addClass(Modal.Selectors.IN);
            });

            animation.onTransitionEnd(this.$backdrop, $.proxy(this._showAnimatedContent, this));
        } else {
            setTimeout(() => {
                _this._initContent();
            });
        }
    }

    /**
     * Shows content in the case that there
     * is a backdrop animation
     * @private
     */
    _showAnimatedContent() {
        this.$container.addClass('fade-in');
        animation.onTransitionEnd(this.$container, $.proxy(this._initContent, this));
    }

    /**
     * Initializes modal content in case there is a video
     * @private
     */
    _initContent() {
        this._initVideo();

        this._addCloseListener();
        this.$element.trigger(Modal.EventType.MODAL_OPEN);
    }

    /**
     * Runs Spark Responsive Images on any responsive image,
     * and adds them to the global array of images to watch
     * @private
     */
    _initVideo() {
        const video = this.$content.find('.video');
        if (video.length > 0) {
            this._createVimeoVideo(video);
        }
    }

    /**
     * Creates parent element for video element
     * if content type is video
     * @private
     */
    _createVideoContainer() {
        // create the aspect box
        const scaler = document.createElement('div');
        scaler.className = 'aspect-16x9 scaler';

        this._content.appendChild(scaler);
        this.$video = $(scaler);
    }

    /**
     * Creates vimeo video iframe element
     * @params {DOM Element} video Video element
     * @private
     */
    _createVimeoVideo(video) {
        const id = video.data('vimeoId');
        const autoplay = this.options.autoplay;

        // create the iframe
        const iframe = document.createElement('iframe');
        iframe.src = `//player.vimeo.com/video/${id}`;
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('allowfullscreen', false);

        if (autoplay) {
            iframe.src += '?autoplay=1';
        }

        this.$video.append(iframe);
    }

    /**
     * Adds click event listeners for closing modal
     * @private
     */
    _addCloseListener() {
        const _this = this;
        this._closeButton.on('click', $.proxy(this._hide, this));

        // These listeners are both necessary in order to enable clicking anywhere
        // outside the modal image, including the left & right padding that's on
        // the .container div; but ignore clicks on the image itself.
        this.$container.on('click', ({ target }) => {
            if ($(target).attr('class') === _this.$container.attr('class')) {
                _this._hide();
            }
        });

        this.$gridContainer.on('click', ({ target }) => {
            if ($(target).attr('class') === _this.$gridContainer.attr('class')) {
                _this._hide();
            }
        });
    }

    /**
     * Hides modal
     * @private
     */
    _hide() {
        if (this.$modal) {
            this.$modal.removeClass(Modal.Selectors.IN);
        }
        // if there is an animation, wait until the backdrop
        // is done animating before removing DOM elements
        this._remove();
    }

    /**
     * Removes modal DOM elements and event listeners for close
     * @private
     */
    _remove() {
        this.$closeButton.remove();
        this.$body.removeClass(Modal.Selectors.OPEN);
        this.$element.append(this.$content);
        this._removeElements();
    }

    _removeElements() {
        if(this.$modal) this.$modal.remove();
        this.$element.trigger(Modal.EventType.MODAL_CLOSE);
        this._dispose();
    }

    /**
     * Clears references to modal in global vars
     * Also disposes of videos
     * @private
     */
    _dispose() {
        SparkWindowEvents.remove(this.resizeId);

        if (this.$video) {
            this._disposeVideo();
        }

        this.$element.append(this.$content);
        this.$modal = null;
        this.$backdrop = null;
        this.$container = null;
        this.resizeId = null;
    }

    /**
     * Removes video and global reference
     * @private
     */
    _disposeVideo() {
        this.$video.remove();
        this.$video = null;
    }

    /**
     * Removes video and global reference
     * @private
     */
    registerTriggers() {
        this._flushTriggers();
        this.triggers = this.$body.find(`*[data-target="${this._id}"]`);
        this._initListeners();
    }

    _flushTriggers() {
        this.triggers.off('click');
        this.triggers = null;
    }

    dispose() {
        this.element = null;
    }
}

/** @enum {!Object} */
Modal.Defaults = {
    center: true,
    centerOnMobile: true,
    isFullScreen: false,
    autoplay: false,
};

/** @enum {!Object} */
Modal.Selectors = {
    IN: 'in',
    CONTENT: 'modal-content',
    RESPONSIVE_IMG: 'spark-responsive-img',
    OPEN: 'modal-open',
};

/** @enum {!Object} */
Modal.EventType = {
    MODAL_OPEN: 'Modal:open',
    MODAL_CLOSE: 'Modal:closed',
};

export default Modal;
