import $ from 'jquery';
import SparkCarousel from 'spark-carousel';
import SparkWindow from 'spark-window-events';
import { array, style } from 'spark-helpers';
import Track from 'js/track';

const evenHeights = style.evenHeights;

class OdoFadeCarousel extends SparkCarousel {
  constructor(element, options) {
    const opts = $.extend(true, {}, OdoFadeCarousel.Defaults, options);
    super(element, opts);

    /**
     * An array of copy elements if they exists
     * on the base module.
     *
     * @type {jQuery|Object}
     * @private
     */
    this._copyElements = null;

    /**
     * Boolean to determine if there are any copy elements
     * present on the base module.
     *
     * @type {boolean}
     * @private
     */
    this._hasCopy = false;

    this._resizeId = null;

    this.onSlideEnd = this.onSlideEnd.bind(this);
    this.on(SparkCarousel.settings.EventType.SLIDE_END, this.onSlideEnd);
    this._init();
  }

  _init() {
    this._copyElements = array.toArray(
        this.element.parentElement.querySelectorAll('.js-copy'));

    this._hasCopy = this._copyElements.length > 0;
    this._setCopyHeight();
    this._initializeCarouselBindings();
  }

  onSlideEnd({ hasSlideChanged }) {
    if (hasSlideChanged) {
      Track.event('fade carousel', 'navigate', 'slide changed');
    }
  }

  _initializeCarouselBindings() {
    this.on(
      SparkCarousel.settings.EventType.SLIDE_START,
      $.proxy(this._handleSlideStart, this));

    this.on(
      SparkCarousel.settings.EventType.SLIDE_START,
      $.proxy(this._handleSlideEnd, this));

    this._resizeId = SparkWindow.onResize(
      $.proxy(this._handleResize, this));
  }

  _setCopyHeight() {
    if (!this._copyElements.length) {
      return;
    }

    const copyHeight = evenHeights(this._copyElements);

    this._copyElements[0].parentElement.style.height = `${copyHeight}px`;
  }

  /**
   * Handles resize events.
   *
   * @private
   */
  _handleResize() {
    this._setCopyHeight();
  }

  /**
   * Events for when the carousel has started the slide change.
   *
   * @param {string} event
   * @private
   */
  _handleSlideStart({ hasSlideChanged }) {
    if (hasSlideChanged && this._hasCopy) {
      $(this._copyElements).removeClass('is-selected');
    }
  }

  /**
   * Events for when the carousel has changed slides.
   *
   * @param {string} event
   * @private
   */
  _handleSlideEnd({ hasSlideChanged, to }) {
    if (hasSlideChanged) {
      Track.event('fade carousel', 'navigate', 'slide changed');
    }

    if (hasSlideChanged && this._hasCopy) {
      $(this._copyElements[to]).addClass('is-selected');
    }
  }

  dispose() {
    this.off(SparkCarousel.settings.EventType.SLIDE_END, this.onSlideEnd);
    super.dispose();
  }
}

/** @enum {!Object} */
OdoFadeCarousel.Defaults = {
  animationSpeed: 250,
  pagination: true,
  isFade: true,
  template: {
    paddleNextInner: '<span class="paddle-inner"><svg viewBox="0 0 17.021 29.798" enable-background="new 0 0 17.021 29.798"><path d="M2.121 0l-2.121 2.121 12.778 12.778-12.778 12.778 2.121 2.121 14.9-14.899z"/></svg></span>',
    paddlePrevInner: '<span class="paddle-inner"><svg viewBox="0 0 17.021 29.798" enable-background="new 0 0 17.021 29.798"><path d="M14.899 29.798l2.122-2.121-12.778-12.778 12.778-12.778-2.122-2.121-14.899 14.899z"/></svg></span>',
  },
  isLooped: true,
};

export default OdoFadeCarousel;
