export default {

  /**
   * Base classname for SparkShare modules.
   */
  ClassName: {
    BASE: 'spark-share',
  },

  /**
   * The window name for all share windows spawned by SparkShare
   */
  WINDOW_NAME: 'SparkShareWindow',
};
