/**
 * @fileoverview Main navigation for Odopod site
 *
 * @author Evan Vaughn
 * @author Glen Cheney
 */

// See comment in `src/css/styles.scss` for why the css isn't imported here.

import $ from 'jquery';
import { animation } from 'spark-helpers';
import SparkTap from 'spark-tap';
import SparkViewport from 'spark-viewport-enter';
import Track from 'js/track';

class MainNav {
  constructor(element) {
    this.element = element;

    /*
     * Element that contains all navigation items
     * @type {Element}
     * @private
     */
    this._navContainer = document.querySelector('.main-nav__nav-container');

    /*
     * Nav link items
     * @type {Element}
     * @private
     */
    this.$navItems = $(element).find('.main-nav__nav-item');

    /*
     * jQuery Collection of all elements that fade in/out with nav toggle
     * @type {Array|jQuery}
     * @private
     */
    this.$hiddenContainers = $(element).find('.js-hidden');

    /*
     * Nav toggle button
     * @type {Element}
     * @private
     */
    this._navToggle = document.querySelector('.js-toggle-nav');

    this._mainContent = document.querySelector('.main-content');

    this._isNavOpen = false;
    this._isAnimating = false;

    this._lastNavItem = this.$navItems[this.$navItems.length - 1];

    this.tapId = SparkTap.addListener(this._navToggle, $.proxy(this._toggleNav, this), true);
    $(this._navToggle).on('keydown', $.proxy(this._handleKeyDown, this));
  }

  /**
   * If the toggle nav button is focused and the enter key is pressed, toggle
   * the navigation.
   * @param {jQuery.Event} event Event object.
   */
  _handleKeyDown(event) {
    if (document.activeElement === this._navToggle && event.which === 13) {
      event.preventDefault();
      this._toggleNav();
    }
  }

  /**
   * Toggles open/close classes for navigation, listening for
   * animation ended
   * @private
   */
  _toggleNav() {
    if (!this._isAnimating) {
      this._isAnimating = true;
      if (this._isNavOpen) {
        this._closeNav();
      } else {
        this._openNav();
      }
    }
  }

  /**
   * Opens navigation
   * @private
   */
  _openNav() {
    this._disableScroll();
    this._navToggle.classList.add('navicon--close');
    this.$hiddenContainers.removeClass('hidden');
    this.element.classList.add('main-nav--is-open');
    this.element.classList.add('main-nav--is-opening');
    this._mainContent.classList.remove('in');

    Track.event('hamburger', 'opened', 'main');

    animation.onTransitionEnd(this._mainContent, this._openedNav, this);
  }

  _openedNav() {
    this.element.classList.remove('main-nav--is-opening');
    this.$hiddenContainers.addClass('in');

    requestAnimationFrame(() => {
      SparkViewport.update();
      this._animateNavItems();
    });
  }

  /**
   * Closes navigation
   * @private
   */
  _closeNav() {
    // god awful hack for firefox running rAF too fast
    requestAnimationFrame(() => {
      setTimeout(() => {
        this._navToggle.classList.remove('navicon--close');
        this.element.classList.remove('main-nav--is-open');
        this.element.classList.add('main-nav--is-closing');

        this.$hiddenContainers.removeClass('in');
        this._mainContent.classList.add('in');
      });
    });

    animation.onTransitionEnd(this._mainContent, this._closedNav, this);
  }

  _closedNav() {
    this.$hiddenContainers.addClass('hidden');
    this.$navItems.removeClass('animate-in');
    this.element.classList.remove('main-nav--is-closing');
    this._isAnimating = false;
    this._isNavOpen = false;
    this._enableScroll();
  }

  /**
   * Animates navigation items
   * @private
   */
  _animateNavItems() {
    this._navContainer.classList.remove('hidden');

    requestAnimationFrame(() => {
      this.$navItems.addClass('animate-in');
    });

    animation.onTransitionEnd(this._lastNavItem, () => {
      this._isAnimating = false;
      this._isNavOpen = true;
    });
  }

  _disableScroll() {
    document.body.classList.add('no-scroll');
  }

  _enableScroll() {
    document.body.classList.remove('no-scroll');
  }

  dispose() {
    this._disposeEvents();
    this.element = null;
    this._navContainer = null;
    this.$navItems = null;
    this.$hiddenContainers = null;
    this._navToggle = null;
    this._mainContent = null;
    this._lastNavItem = null;
  }

  _disposeEvents() {
    this.$navToggles.off('click', $.proxy(this._toggleNav, this));
    $(this._navToggle).off('keydown');
  }

  static initializeAll() {
    return $('.main-nav').map((i, el) => new MainNav(el)).get();
  }
}

export default MainNav;
