/**
 * @fileoverview Global footer for boqobo site
 *
 * @author Evan Vaughn
 */

import $ from 'jquery';
import { style } from 'spark-helpers';
import SparkWindowEvents from 'spark-window-events';

class GlobalFooter {
  constructor(element) {
    this.element = element;
    this._columns = document.querySelectorAll('.global-footer__footer-column');
    this._mql = window.matchMedia('(max-width:767px)');
    this.isSmallScreen = this._mql.matches;
    this._resizeId = SparkWindowEvents.onResize($.proxy(this._runEqualHeights, this));
    this._runEqualHeights();
    this._isWaiting = false;
    this._submitDisabled = true;

    this._newsletterFormEl = this.element.querySelector('.global-footer__newsletter-form');
    this._newsletterEmailField = this.element.querySelector('.global-footer__newsletter-form__email');
    this._newsletterCtaEl = this.element.querySelector('.global-footer__newsletter-cta');
    this._newsletterSuccessEl = this.element.querySelector('.global-footer__newsletter-success');
    this._newsletterSubmitEl = this.element.querySelector('.global-footer__newsletter-form__submit');
    this._odomailEl = this.element.querySelector('.global-footer__newsletter-form__email');
    this._onNewsletterSubscribe = this._handleNewsSubmit.bind(this);
    this._onNewsletterCtaClick = this._handleNewsCtaClick.bind(this);
    this._onNewsletterEmailFieldKeyUp = this._handleNewsEmailKeyup.bind(this);
    $(this._newsletterCtaEl).on('click', this._onNewsletterCtaClick);
    $(this._newsletterFormEl).on('submit', this._onNewsletterSubscribe);
    $(this._newsletterEmailField).on('keyup', this._onNewsletterEmailFieldKeyUp);
  }

  _runEqualHeights() {
    this.isSmallScreen = this._mql.matches;

    if (this.isSmallScreen) {
      $.each(this._columns, function each() {
        this.style.height = '';
      });
    } else {
      style.evenHeights(this._columns);
    }
  }

  _handleNewsCtaClick(evt) {
    evt.preventDefault();

    $(this._newsletterCtaEl).stop().fadeOut(200, () => {
      $(this._newsletterFormEl).fadeIn(200, () => {
        this._odomailEl.focus();
      });
    });
  }

  _handleNewsEmailKeyup({ currentTarget }) {
    if (this._validateEmail(currentTarget.value)) {
      this._enableSubmit();
    } else {
      this._disableSubmit();
    }
  }

  _enableSubmit() {
    if (this._submitDisabled) {
      this._submitDisabled = false;

      $(this._newsletterSubmitEl).addClass('active');
    }
  }

  _disableSubmit() {
    if (!this._submitDisabled) {
      this._submitDisabled = true;

      $(this._newsletterSubmitEl).removeClass('active');
    }
  }

  _handleNewsSubmit(evt) {
    evt.preventDefault();

    if (this._isWaiting || this._submitDisabled) {
      return;
    }

    const options = {
      url: '/newsletter',
      method: 'post',
      dataType: 'json',
      data: {
        email: this._odomailEl.value,
      },
    };

    $.ajax(options)
      .then(this._handleSuccess.bind(this))
      .fail(this._handleError.bind(this))
      .always(this._handleRequestFinished.bind(this));

    this._disableForm();
  }

  _disableForm() {
    this._isWaiting = true;
    this._odomailEl.disabled = true;
  }

  _enableForm() {
    this._isWaiting = false;
    this._odomailEl.disabled = false;
  }

  _handleSuccess() {
    $(this._newsletterFormEl).stop().fadeOut(200, () => {
      $(this._newsletterSuccessEl).fadeIn(200);
    });
  }

  _handleError(...args) {
    console.log('(╯°□°）╯︵ ┻━┻'); // eslint-disable-line no-console
    console.log(args); // eslint-disable-line no-console
  }

  _handleRequestFinished() {
    this._enableForm();
  }

  dispose() {
    this.element = null;
    this._columns = null;
    this._odomailEl = null;
    this._newsletterFormEl = null;
    this._newsletterSuccessEl = null;
    SparkWindowEvents.remove(this._resizeId);
  }

  _validateEmail(email) {
    const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
  }

  static initializeAll() {
    return $('.global-footer').map((i, el) => new GlobalFooter(el)).get();
  }
}

export default GlobalFooter;
