'use strict';

let Device = {};

/**
 * Object Model prefixes.
 * @type {string}
 * @private
 */
let prefixes = 'Webkit Moz O ms'.split(' ');

/**
 * Prefix lookup cache.
 * @type {Object}
 * @private
 */
let prefixCache = {};

let isDefined = (thing) => {
  return typeof thing !== 'undefined';
};

let getCacheName = (property, value, isValueDefined) => {
  if (isValueDefined) {
    return property + value;
  }

  return property;
};

let element = document.createElement('div');

/**
 * Returns the prefixed style property if it exists.
 * {@link http://perfectionkills.com/feature-testing-css-properties/}
 *
 * @param {string} propName The property name.
 * @param {string} [value] Value to set and test. If undefined, this test will
 *     only check that the property exists, not whether it supports the value.
 * @return {string|boolean} The style property or false.
 */
Device.prefixed = (property, value) => {
  let shouldTestValue = isDefined(value);
  let cacheName = getCacheName(property, value, shouldTestValue);

  // Check cache.
  if (prefixCache[cacheName]) {
    return prefixCache[cacheName];
  }

  let ucProp = property.charAt(0).toUpperCase() + property.slice(1);

  // Create an array of prefixed properties. ['transform', 'WebkitTransform'].
  let props = (property + ' ' + prefixes.join(ucProp + ' ') + ucProp).split(' ');
  let style = element.style;
  let i = 0;
  let length = props.length;

  for (; i < length; i++) {
    let prop = props[i];
    let before = style[prop];

    // Check the existence of the property.
    if (isDefined(before)) {

      // Test if the value sticks after setting it.
      if (shouldTestValue) {
        style[prop] = value;

        // If the property value has changed, assume the value used is supported.
        if (style[prop] !== before) {
          prefixCache[cacheName] = prop;
          return prop;
        }

      } else {
        prefixCache[cacheName] = prop;
        return prop;
      }
    }
  }

  prefixCache[cacheName] = false;
  return false;
};

/**
 * Hyphenates a javascript style string to a css one. For example:
 * MozBoxSizing -> -moz-box-sizing.
 *
 * @param {string|boolean} str The string to hyphenate.
 * @return {string} The hyphenated string.
 */
Device.hyphenate = (str) => {

  // Catch booleans.
  if (!str) {
    return '';
  }

  // Turn MozBoxSizing into -moz-box-sizing.
  return str.replace(/([A-Z])/g, (str, m1) => {
    return '-' + m1.toLowerCase();
  }).replace(/^ms-/, '-ms-');
};

/**
 * Prefixed style properties.
 * @enum {string|boolean}
 */
Device.Dom = {
  ANIMATION: Device.prefixed('animation'),
  ANIMATION_DURATION: Device.prefixed('animationDuration'),
  TRANSFORM: Device.prefixed('transform'),
  TRANSITION: Device.prefixed('transition'),
  TRANSITION_PROPERTY: Device.prefixed('transitionProperty'),
  TRANSITION_DURATION: Device.prefixed('transitionDuration'),
  TRANSITION_TIMING_FUNCTION: Device.prefixed('transitionTimingFunction'),
  TRANSITION_DELAY: Device.prefixed('transitionDelay'),
};

/**
 * Prefixed css properties.
 * @enum {string}
 */
Device.Css = {
  ANIMATION: Device.hyphenate(Device.Dom.ANIMATION),
  ANIMATION_DURATION: Device.hyphenate(Device.Dom.ANIMATION_DURATION),
  TRANSFORM: Device.hyphenate(Device.Dom.TRANSFORM),
  TRANSITION: Device.hyphenate(Device.Dom.TRANSITION),
  TRANSITION_PROPERTY: Device.hyphenate(Device.Dom.TRANSITION_PROPERTY),
  TRANSITION_DURATION: Device.hyphenate(Device.Dom.TRANSITION_DURATION),
  TRANSITION_TIMING_FUNCTION: Device.hyphenate(Device.Dom.TRANSITION_TIMING_FUNCTION),
  TRANSITION_DELAY: Device.hyphenate(Device.Dom.TRANSITION_DELAY),
};

/**
 * Whether the browser has css transitions.
 * @type {boolean}
 */
Device.HAS_TRANSITIONS = Device.Dom.TRANSITION !== false;

/**
 * Whether the browser has css animations.
 * @type {boolean}
 */
Device.HAS_CSS_ANIMATIONS = Device.Dom.ANIMATION !== false;

/**
 * Whether the browser has css transitions.
 * @type {boolean}
 */
Device.HAS_TRANSFORMS = Device.Dom.TRANSFORM !== false;

/**
 * The browser can use css transitions and transforms.
 * @type {boolean}
 */
Device.CAN_TRANSITION_TRANSFORMS = Device.HAS_TRANSITIONS &&
  Device.HAS_TRANSFORMS;

/**
 * The browser can use 3d css transforms.
 * @deprecated Layer promotion should be achieved via different properties like
 *     `will-change` or `backface-visibility`.
 * @type {boolean}
 */
Object.defineProperty(Device, 'HAS_3D_TRANSFORMS', {
  get() {
    console.warn('Deprecated: Layer promotion should be achieved via different' +
      ' properties like `will-change` or `backface-visibility`. This method' +
      ' always returns `false`.');
    return false;
  },
});

/**
 * Whether the browser supports touch events.
 * @type {boolean}
 */
Device.HAS_TOUCH_EVENTS = ('ontouchstart' in window) ||
  !!window.DocumentTouch && document instanceof window.DocumentTouch;

Device._HAS_UNPREFIXED_POINTER_EVENTS = !!navigator.pointerEnabled;

/**
 * Whether the browser supports pointer events.
 * @type {boolean}
 */
Device.HAS_POINTER_EVENTS = Device._HAS_UNPREFIXED_POINTER_EVENTS ||
  !!navigator.msPointerEnabled;

/**
 * Whether the browser supports `localStorage`. Safari in private browsing
 * throws an error when calling `setItem`.
 * @type {boolean}
 */
Device.HAS_LOCAL_STORAGE = (() => {
  try {
    let testKey = 'test';
    localStorage.setItem(testKey, '1');
    localStorage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }
})();

export default Device;
