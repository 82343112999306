/**
 * DesignModeToggle constructor.
 *
 * @constructor
 */
const DesignModeToggle = () => {
  document.addEventListener('keydown', ({ shiftKey, keyCode }) => {
    if (shiftKey && keyCode === 186) { // Shift + Semicolon
      if (document.designMode === 'on') {
        document.designMode = 'off';
      } else {
        document.designMode = 'on';
      }
    }
  });
};

export default new DesignModeToggle();
