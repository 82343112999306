'use strict';

import { array } from './array';

const dom = {

  /**
   * Retrieves the first child which is an element from a parent node.
   * @param {Element} node Parent node.
   * @return {?Element} The first element child or null if there isn't one.
   */
  getFirstElementChild(node) {
    return node.firstElementChild;
  },

  /**
   * Removes all children from a parent node.
   * @param {Element} element Parent node.
   */
  removeChildren(element) {
    element.textContent = '';
  },

  /**
   * Retrieves all children (excluding text nodes) for an element. `children` is
   * available in IE9+, but does not work for document fragments nor SVG.
   * @param {Element} element Parent node.
   * @return {!Array.<Element>} A real array of child elements.
   */
  getChildren(element) {
    return array.toArray(element.children);
  },

  /**
   * Swaps element1 with element2 in the DOM.
   * @param {Element} elm1 first element.
   * @param {Element} elm2 second element.
   */
  swapElements(elm1, elm2) {
    if (!elm1 || !elm2) {
      return;
    }

    let parent1 = elm1.parentNode;
    let next1 = elm1.nextSibling;
    let parent2 = elm2.parentNode;
    let next2 = elm2.nextSibling;

    parent1.insertBefore(elm2, next1);
    parent2.insertBefore(elm1, next2);
  },

  /**
   * Ripped from: goog.testing.editor.dom.getRelativeDepth_.
   *
   * Returns the depth of the given node relative to the given parent node, or -1
   * if the given node is not a descendant of the given parent node. E.g. if
   * node == parentNode returns 0, if node.parentNode == parentNode returns 1,
   * etc.
   * @param {Node} node Node whose depth to get.
   * @param {Node} parentNode Node relative to which the depth should be
   *     calculated.
   * @return {number} The depth of the given node relative to the given parent
   *     node, or -1 if the given node is not a descendant of the given parent
   *     node.
   */
  getRelativeDepth(node, parentNode) {
    let depth = 0;
    while (node) {
      if (node === parentNode) {
        return depth;
      }

      node = node.parentNode;
      depth++;
    }

    return -1;
  },

  getPreviousElementSibling(node) {
    return node.previousElementSibling;
  },

  getNextElementSibling(node) {
    return node.nextElementSibling;
  },

  /**
   * Retrieves the nth sibling of an element, or null if the would be nth sibling
   * does not exist. Heads up! This function excludes text nodes.
   * @param {Element} node Element to start looking from.
   * @param {number} n An integer representing the desired element relative to
   *     `node`. For example, `2` would look for `node.nextSibling.nextSibling`.
   * @param {boolean=} optIsForward Whether to look forwards or backwards. Default is true.
   * @return {?Element} The nth sibling or null.
   */
  getNthSibling(node, n, optIsForward) {
    let isForward = optIsForward !== false;
    let siblingCount = 0;
    do {
      node = isForward ?
        dom.getNextElementSibling(node) :
        dom.getPreviousElementSibling(node);
      siblingCount++;
    } while (node && siblingCount < n);
    return node;
  },

  /**
   * Returns a promise for interactive ready state / DOMContentLoaded event trigger
   * Page has finished loading but external resources are still loading
   * @return {Promise} Document state promise
   */
  get ready() {
    return new Promise((resolve) => {
      if (document.readyState === 'interactive') {
        resolve();
      } else {
        document.addEventListener('DOMContentLoaded', function ready() {
          document.removeEventListener('DOMContentLoaded', ready);
          resolve();
        });
      }
    });
  },

  /**
   * Returns as promise for complete ready state / load event trigger
   * Page has completely finished loading
   * @return {Promise} Document state promise
   */
  get loaded() {
    return new Promise((resolve) => {
      if (document.readyState === 'complete') {
        resolve();
      } else {
        window.addEventListener('load', function complete() {
          window.removeEventListener('load', complete);
          resolve();
        });
      }
    });
  },
};

export { dom };
